import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Grid,
  Card,
  Typography,
  CardContent,
  Box,
  Alert,
  IconButton,
} from "@mui/material";
import VideoUploadModal from "./VideoUploadModal";
import useUploadVideo from "../../hooks/useUploadVideo";
import UploadIcon from "@mui/icons-material/CloudUpload";
import ShortsIcon from "@mui/icons-material/PlayCircleOutline";
import WithSidebar from "../layout/WithSidebar";

const AllStreams = ({ auth }) => {
  const [streams, setStreams] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showShorts, setShowShorts] = useState(false);
  const [filteredVideos, setFilteredVideos] = useState([]);
  const [shorts, setShorts] = useState([]);
  const { uploadVideo, fetchVideos, videos, uploading, error } = useUploadVideo();

  const fetchStreams = async () => {
    try {
      const response = await fetch(`/streams?limit=10&published=true`);
      const data = await response.json();
      if (data?.data) {
        setStreams(data.data);
      }
    } catch (err) {
      console.error("Failed to fetch streams:", err);
    }
  };

  const handleUpload = async (formData) => {
    const result = await uploadVideo(
      formData.get("video"),
      formData.get("title"),
      formData.get("description")
    );
    if (result.success) {
      fetchVideos();
    }
  };

  const processVideos = () => {
    const regularVideos = [];
    const verticalVideos = [];

    videos.forEach((video) => {
      const tempVideo = document.createElement("video");
      tempVideo.src = video.videoUrl;

      tempVideo.onloadedmetadata = () => {
        if (tempVideo.videoHeight > tempVideo.videoWidth) {
          verticalVideos.push(video);
        } else {
          regularVideos.push(video);
        }

        if (regularVideos.length + verticalVideos.length === videos.length) {
          setFilteredVideos(regularVideos);
          setShorts(verticalVideos);
        }
      };
    });
  };

  useEffect(() => {
    fetchStreams();
    fetchVideos();
  }, []);

  useEffect(() => {
    if (videos.length > 0) {
      processVideos();
    }
  }, [videos]);

  return (
    <WithSidebar>
      <Box
        sx={{
          padding: "20px",
          backgroundColor: "#181818",
          minHeight: "100vh",
          color: "#FFFFFF",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflowY: "auto",
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            marginBottom: "30px",
            color: "#FFFFFF",
          }}
        >
          {streams.length > 0 ? "Live Streams" : showShorts ? "Shorts" : "Uploaded Videos"}
        </Typography>

        {error && (
          <Alert
            severity="error"
            sx={{
              backgroundColor: "#4a0000",
              color: "#FFFFFF",
              marginBottom: "20px",
            }}
          >
            {error}
          </Alert>
        )}

        <Box
          sx={{
            position: "fixed",
            bottom: { xs: "10px", sm: "20px" },
            right: { xs: "10px", sm: "20px" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: { xs: 2, sm: 3 },
            zIndex: 1000,
          }}
        >
          {/* Shorts Toggle: Available to all users */}
          <IconButton
            onClick={() => setShowShorts((prev) => !prev)}
            aria-label="Toggle Shorts View"
            sx={{
              color: "#FFFFFF",
              backgroundColor: showShorts ? "#CC0000" : "#202020",
              "&:hover": { backgroundColor: "#505050" },
              width: { xs: "60px", sm: "70px" },
              height: { xs: "60px", sm: "70px" },
              borderRadius: "50%",
              boxShadow: 3,
              transition: "transform 0.2s ease-in-out",
              "&:active": { transform: "scale(1.1)" },
            }}
          >
            <ShortsIcon sx={{ fontSize: { xs: "28px", sm: "32px" } }} />
          </IconButton>

          {/* Upload Video: Restricted to authenticated users */}
          {auth?.isAuthenticated && (
            <IconButton
              onClick={() => setIsModalOpen(true)}
              aria-label="Upload Video"
              sx={{
                color: "#FFFFFF",
                backgroundColor: "#202020",
                "&:hover": { backgroundColor: "#505050" },
                width: { xs: "60px", sm: "70px" },
                height: { xs: "60px", sm: "70px" },
                borderRadius: "50%",
                boxShadow: 3,
                transition: "transform 0.2s ease-in-out",
                "&:active": { transform: "scale(1.1)" },
              }}
            >
              <UploadIcon sx={{ fontSize: { xs: "28px", sm: "32px" } }} />
            </IconButton>
          )}
        </Box>

        {showShorts ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              maxWidth: "400px",
            }}
          >
            {shorts.map((video) => (
              <Box
                key={video.id}
                sx={{
                  width: "100%",
                  marginBottom: "20px",
                  borderRadius: "12px",
                  overflow: "hidden",
                }}
              >
                <video
                  controls
                  style={{
                    width: "100%",
                    height: "600px",
                    objectFit: "cover",
                  }}
                  src={video.videoUrl}
                />
                <Typography
                  variant="h6"
                  sx={{
                    marginTop: "10px",
                    color: "#FFFFFF",
                    textAlign: "center",
                  }}
                >
                  {video.title}
                </Typography>
                <Typography variant="body2" sx={{ color: "#AAAAAA", textAlign: "center" }}>
                  {video.description}
                </Typography>
              </Box>
            ))}
          </Box>
        ) : (
          <Grid container spacing={3}>
            {streams.map((stream) => (
              <Grid item key={stream._id} xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    backgroundColor: "#202020",
                    "&:hover": { boxShadow: 4 },
                  }}
                >
                  <Link to={`/live/${stream._id}`} style={{ textDecoration: "none" }}>
                    <Box sx={{ position: "relative", height: "200px" }}>
                      <img
                        src={stream.thumbnail}
                        alt={stream.title}
                        loading="lazy"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "4px",
                        }}
                      />
                      <Typography
                        sx={{
                          position: "absolute",
                          top: 8,
                          left: 8,
                          backgroundColor: "#CC0000",
                          color: "#FFFFFF",
                          padding: "2px 8px",
                          borderRadius: "4px",
                          fontSize: "12px",
                        }}
                      >
                        LIVE
                      </Typography>
                    </Box>
                    <CardContent>
                      <Typography variant="h6" noWrap sx={{ color: "#FFFFFF" }}>
                        {stream.title || "Untitled Stream"}
                      </Typography>
                      <Typography variant="body2" sx={{ color: "#AAAAAA" }}>
                        {stream.categoryId?.name || "Uncategorized"}
                      </Typography>
                    </CardContent>
                  </Link>
                </Card>
              </Grid>
            ))}
            {filteredVideos.map((video) => (
              <Grid item key={video.id} xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    backgroundColor: "#202020",
                    "&:hover": { boxShadow: 4 },
                  }}
                >
                  <Box sx={{ position: "relative", paddingBottom: "56.25%", height: 0 }}>
                    <video
                      controls
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        borderRadius: "4px",
                      }}
                      src={video.videoUrl}
                    />
                  </Box>
                  <CardContent>
                    <Typography variant="h6" noWrap sx={{ color: "#FFFFFF" }}>
                      {video.title}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "#AAAAAA" }}>
                      {video.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}

        <VideoUploadModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onUpload={handleUpload}
        />
      </Box>
    </WithSidebar>
  );
};

AllStreams.propTypes = {
  auth: PropTypes.object,
  genre: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  genre: state.genre,
});

export default connect(mapStateToProps)(AllStreams);




// import React, { useEffect, Fragment, useState } from "react";
// import { Link, withRouter, useLocation } from "react-router-dom";
// import PropTypes from "prop-types";
// import { connect } from "react-redux";
// import { getDashboard, getDashboardGraphs } from "../../actions/dashboardRoute";
// import { getAllGenre } from "../../actions/genreAction";
// import { Bar, Doughnut } from "react-chartjs-2";
// import { logout } from "../../actions/authAction";
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
// import WithSidebar from "../layout/WithSidebar";
// import SvgIcon from "../../Components/SvgIcon";
// import "oAll Videost [streams, setstreams] = useState([]);

//   //TODO: use this for top live
//   const {
//     data,
//     loading: liveStreamLoading,
//     error,
//   } = useFetch(`/streams?limit=10?published=true&page=${page}`);

//   console.log(data?.data);

//   useEffect(() => {
//     if (!data) return;
//     setstreams([...streams, ...data.data]);
//   }, [data]);

//   return (
//     <WithSidebar>
//       <div className="">
//         <div className="">
//           <h4 style={{ marginBottom: "20px" }}>All Videos</h4>
//           {/* TODO: implement infinite scrolling */}
//           {liveStreamLoading && streams.length < 0 && <ViewLoader />}
//           {streams.length < 0 ? (
//             <RecordNotFound />
//           ) : (
//             <InfiniteScroll
//               className="content-slider row"
//               dataLength={data && data.pagination.totalPage}
//               next={(_) => {
//                 setPage(page + 1);
//                 console.log("Next page", _);
//               }}
//               hasMore={data && (data.pagination.hasNextPage ?? true)}
//               loader={<h4>Loading...</h4>}
//               // endMessage={
//               //   <p style={{ textAlign: "center" }} className="col-12">
//               //     <b>Yay! You have seen it all</b>
//               //   </p>
//               // }
//             >
//               {streams.map((item, index) => (
//                 <Link
//                   key={index}
//                   to={`/live/${item?._id}`}
//                   className="col-sm-6 col-md-4 col-lg-3"
//                 >
//                   <div className="item">
//                     <div className="">
//                       <div className="position-relative">
//                         <div className="live-badge">LIVE</div>
//                         <img
//                           src={item?.thumbnail}
//                           alt=""
//                           style={{ height: "300px", width: "420px" }}
//                           className="img-fluid"
//                         />
//                         <div className="viewers">
//                           <SvgIcon iconName="Viewers" /> {item?.views}
//                         </div>
//                       </div>
//                       <div className="slider-caption mt-2">
//                         <p className="streamer">
//                           ${item?.streamer?.firstname} $
//                           {item?.streamer?.lastname}
//                         </p>
//                         <h5 className="title">{item?.title}</h5>
//                         <p className="category">
//                           {item?.categoryId === null
//                             ? "Uncategorized"
//                             : item?.categoryId?.name}
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                 </Link>
//               ))}
//             </InfiniteScroll>
//           )}
//         </div>
//       </div>
//     </WithSidebar>
//   );
// };

// AllStreawm.propTypes = {};

// const mapStateToProps = (state) => ({
//   auth: state.auth,
//   genre: state.genre,
// });

// export default connect(mapStateToProps, {
//   logout,
//   getAllGenre,
// })(withRouter(AllStreawm));
